import React, {useState} from 'react';
import {useAuth} from "../auth/AuthContext.tsx";
import './LoginComponent.css';
import {RecoveryButtonComponent} from "../auth/RecoveryButtonComponent.tsx";
import {MAIN_PAGE_URL, REGISTER_URL} from "../request/RequestSender.ts";
import {Link, useNavigate} from "react-router-dom";
import {validate} from "../validation/Validator.ts";


const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [error, setError] = useState<string | null>(null);

    const { auth } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = validate(email, password);
        if (errors.email !== '' || errors.password !== '') {
            setError(errors.email || errors.password);
            return;
        } else {
            setError(null);
        }
        auth(email, password)
            .then(() => {navigate(MAIN_PAGE_URL);})
            .catch((error) => setError(error.message));
    };

    return (
        <>
            <RecoveryButtonComponent/>
            <div className='login-form-wrapper'>
                <form onSubmit={handleSubmit} className='login-form'>
                    <div className='email-input'>
                        <input
                            type="text"
                            placeholder="Почта"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}/>
                    </div>
                    <div className='password-input'>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Пароль"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}/>
                        <i className="fa-solid fa-eye"
                           onClick={(event) => {
                               event.preventDefault();
                               setShowPassword(!showPassword)
                           }}/>
                    </div>
                    <button type="submit"><label>Войти</label></button>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                </form>
                <Link to={REGISTER_URL}>
                <button className="register-button"><label>Регистрация</label></button>
                </Link>
            </div>
        </>
    );
};

export default LoginForm;