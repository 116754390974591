import MasterPasswordInputComponent from "../password/MasterPasswordInputComponent.tsx";
import CredFilter from "./CredFilter.tsx";
import CredList from "./CredList.tsx";
import {useState} from "react";
import {addCred, editCred, getAllCreds, removeCred} from "../request/RequestSender.ts";
import {Cred} from "./Cred.ts";
import './CredComponent.css'
import {LogoutComponent} from "../login/LogoutComponent.tsx";
import {useCrypto} from "../crypt/CryptoContext.tsx";
import useErrorParser from "../../error/useErrorParser.ts";
import ActionCredComponent from "./action/ActionCredComponent.tsx";
import {RecoveryCredComponent} from "./RecoveryCredComponent.tsx";

export default function CredComponent() {
    const [creds, setCreds] = useState<Cred[]>([]);
    const [isMasterPasswordSet, setIsMasterPasswordSet] = useState(false);
    const {encryptCred, decryptCred} = useCrypto();
    const errorParser = useErrorParser();

    const fetchDecryptedData = async () => {
        const response = await getAllCreds(errorParser);
        const encrypted: Cred[] = await Promise.all(response.creds.map(cred => {
            return decryptCred(cred)
        }));
        setCreds(encrypted)
    };

    if (!isMasterPasswordSet) {
        return (
            <MasterPasswordInputComponent isMasterPasswordSet={isMasterPasswordSet}
                                          setIsMasterPasswordSet={setIsMasterPasswordSet}
                                          fetchDecryptedData={fetchDecryptedData}
            />
        );
    }


    const handleAddCred = (cred: Cred) => {
        encryptCred(cred)
            .then(encryptedCred => {
                addCred(encryptedCred, errorParser)
                    .then((added: Cred) => {
                        decryptCred(added)
                            .then(decrypted => setCreds([...creds, decrypted]))
                    });
            });
    };

    const handleDeleteCred = (index: string) => {
        removeCred(errorParser, index).then( () => {
            setCreds(creds.filter((cred) => cred.id !== index))
        });
    };

    const  handleEditCred = (cred: Cred) => {
        encryptCred(cred).then(encryptCred => {
            editCred(errorParser, encryptCred).then((editedCred: Cred) => {
                decryptCred(editedCred).then(decrypted => {
                    const index = creds.findIndex((cred) => cred.id == decrypted.id);
                    creds[index] = decrypted;
                    setCreds([...creds]);
                })
            })
        })
    };


    const handleFilterCred = async (siteName: string) => {
        const response = await getAllCreds(errorParser, siteName);
        const encrypted: Cred[] = await Promise.all(response.creds.map(cred => decryptCred(cred)));
        setCreds(encrypted)
    }

    return (
        <>
            <div className="credWrapper">
                <div className="cred-header">
                    <LogoutComponent/>
                    <ActionCredComponent cred={Cred.emptyCred()} onActionCred={handleAddCred} buttonClassName='fa-solid fa-plus' className='addCredForm'/>
                    <MasterPasswordInputComponent isMasterPasswordSet={isMasterPasswordSet}
                                                  setIsMasterPasswordSet={setIsMasterPasswordSet}
                                                  fetchDecryptedData={fetchDecryptedData}
                    />

                </div>
                <div className="cred-main">
                    <CredFilter filterCred={handleFilterCred}/>
                    <CredList creds={creds}
                              onDeleteCred={handleDeleteCred}
                              onEditCred={handleEditCred}
                              fetchDecryptedData={fetchDecryptedData}
                    />
                </div>
            </div>
        </>
    );
}