import React, {useState} from "react";
import {useCrypto} from "../crypt/CryptoContext.tsx";
import {Cred} from "../cred/Cred.ts";
import {getSalt} from "../request/RequestSender.ts";
import useErrorParser from "../../error/useErrorParser.ts";
import {validateMasterPassword} from "../validation/Validator.ts";
import './MasterPasswordInputComponent.css'

interface KeyInputComponentProps {
    isMasterPasswordSet: boolean,
    setIsMasterPasswordSet: (isMasterPasswordSet: boolean) => void,
    creds?: Cred[],
    fetchDecryptedData: () => Promise<void>
}

export default function MasterPasswordInputComponent({
                                                         isMasterPasswordSet,
                                                         setIsMasterPasswordSet,
                                                         fetchDecryptedData
                                                     }: KeyInputComponentProps) {
    const {setMasterKey} = useCrypto();
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [masterPassword, setMasterPassword] = useState('');
    const [errors, setErrors] = useState({
        masterPassword: '',
    });
    const errorParser = useErrorParser();

    const handleOpenModal = () => {
        setIsLoginModalOpen(true);
    };

    const handleMasterPassword = () => {
        if (masterPassword !== '') {
            getSalt(errorParser)
                .then(salt => {
                    setMasterKey(masterPassword, salt);
                    setIsMasterPasswordSet(true);
                })
                .then(() => {
                    fetchDecryptedData()
                        .catch(() => {throw new Error('Failed to fetch decrypted data')});
                })
                .catch(() => {throw new Error('Failed to get salt')});
        }
    };


    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newErrors = validateMasterPassword(masterPassword);
        if (Object.values(newErrors).some((error) => error !== '')) {
            setErrors(newErrors);
        } else {
            handleMasterPassword();
            setIsLoginModalOpen(false);
            setErrors({masterPassword: ''});
            setMasterPassword('');
        }
    };

    return (
        <div className="keyInputComponent">
            <button onClick={handleOpenModal}><i className="fa-solid fa-key"></i></button>
            {(!isMasterPasswordSet || isLoginModalOpen) && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <form className='master-password-form' onSubmit={handleFormSubmit}>
                            <label>Введите мастер пароль</label>
                            <div className='password-input'>
                                <input
                                    type={showPassword ? 'text' : "password"}
                                    value={masterPassword}
                                    onChange={(event) => setMasterPassword(event.target.value)}
                                />
                                <i className="fa-solid fa-eye" onClick={(event) => {
                                    event.preventDefault();
                                    setShowPassword(!showPassword)
                                }}/>
                            </div>
                            {errors.masterPassword !== '' && <p style={{color: 'red'}}>{errors.masterPassword}</p>}
                            <button type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}