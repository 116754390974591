import React, {createContext, useContext, useEffect, useState} from 'react';
import {authRequest, checkAuth, logoutRequest, MAIN_PAGE_URL} from "../request/RequestSender.ts";
import {useLocation} from "react-use";
import useErrorParser from "../../error/useErrorParser.ts";

const AuthContext = createContext({
    isAuthenticated: false,
    isLoading: false,
    auth: (login: string, password: string): Promise<void | Error> => {},
    logout: (): Promise<void | Error> => {}
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const errorParser = useErrorParser();
    const location = useLocation();

    useEffect(() => {
        if (isAuthenticated) return;
        if (location.pathname === MAIN_PAGE_URL) {
            setLoading(true);
            checkAuth()
                .then(isAuth => {
                    setIsAuthenticated(isAuth);
                    setLoading(false);
                })
        }
    }, []);


    const auth = async (email: string, password: string): Promise<void> => {
        setLoading(true)
        return await authRequest(errorParser, email, password)
            .then(() => {
                setIsAuthenticated(true);
            })
            .then(
                () => {
                    setLoading(false)
                }
            );
    }

    const logout = async () => {
        return await logoutRequest(errorParser)
            .then(() => {
                setIsAuthenticated(false);
            })
    }


    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, auth, logout }}>
            {children}
        </AuthContext.Provider>
    );
};


export const useAuth = () => useContext(AuthContext);