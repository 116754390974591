import React, {useState} from "react";
import {MAIN_PAGE_URL, verifyRegisterRequest} from "../request/RequestSender.ts";
import './RegisterComponent.css';
import {LoginButtonComponent} from "../auth/LoginButtonComponent.tsx";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../auth/AuthContext.tsx";
import useErrorParser from "../../error/useErrorParser.ts";

interface RegisterVerifierDivProps {
    email: string,
    password: string,
    setEmail: (value: (((prevState: string) => string) | string)) => void,
    setPassword: (value: (((prevState: string) => string) | string)) => void
}

export default function RegisterRegisterVerifierDiv({
                                                        email,
                                                        password,
                                                        setEmail,
                                                        setPassword
                                                    }: RegisterVerifierDivProps) {
    const [code, setCode] = useState('');
    const [error, setError] = useState<string | null>(null);
    const errorParser = useErrorParser();

    const validateCode = (code: string) => {
        const regex = /^[0-9]{6}$/;
        return regex.test(code);
    };


    const validateForm = () => {
        const errors: { code: string; } = {
            code: '',
        };

        if (code.trim() === '') {
            errors.code = 'Введите код подтверждения';
        }
        if (!validateCode(code)) {
            errors.code = 'Код должен состоять из 6 цифр';
        }

        return errors;
    }

    const {auth} = useAuth();
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = validateForm();
        if (errors.code !== '') {
            setError(errors.code);
            return;
        }
        verifyRegisterRequest(errorParser, code, email)
            .then(() => {
                    setCode('');
                    auth(email, password)
                        .then(() => {
                            setEmail('');
                            setPassword('');
                            navigate(MAIN_PAGE_URL);
                        })
                        .catch((error) => setError(error.message))
                }
            )
            .catch(error => setError(error.message));
    }

    return (
        <>
            <LoginButtonComponent/>
            <form onSubmit={handleSubmit} className='register-form'>
                <div className='register-form-wrapper'>
                    <input
                        type="text"
                        placeholder="Код подтверждения"
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                    />
                    <button type="submit"><label>Подтверждение регистрации</label></button>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                </div>
            </form>
        </>
    )
}