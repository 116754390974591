import {Cred} from "./Cred.ts";
import {useState} from "react";
import {CopyTextComponent} from "../CopyTextComponent.tsx";
import ActionCredComponent from "./action/ActionCredComponent.tsx";
import {LinkTextComponent} from "../LinkTextComponent.tsx";

interface TodoProps {
    cred: Cred;
    onDeleteCred: (index: string) => void;
    onEditCred: (newCred: Cred) => void;
}

export default function CredCard({cred, onDeleteCred, onEditCred}: TodoProps) {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <li className='credItem'>

            { <LinkTextComponent siteName={cred.siteName} /> }
            { <CopyTextComponent text={cred.username}/> }
            { <CopyTextComponent text={cred.password} showText={showPassword}/> }

            <div className='actionsContainer'>
                <button onClick={() => {
                    setShowPassword(!showPassword)
                }}>
                    <i className="fa-solid fa-eye"></i>
                </button>
                <ActionCredComponent cred={cred} onActionCred={onEditCred} buttonClassName='fa-solid fa-pen-to-square'/>
                <button onClick={() => {
                    if (confirm('Вы уверены что хотите удалить запись?')) {
                        onDeleteCred(cred.id)
                    }
                }}>
                    <i className="fa-regular fa-trash-can"></i>
                </button>
            </div>
        </li>
    )
}

