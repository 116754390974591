import {Cred} from "../cred/Cred.ts";

export class CryptoService {
    static ALGORITHM: string = "AES-GCM";
    static HASH: string = "SHA-256";
    static KEY_SIZE: number = 256;
    static ITERATION_COUNT: number = 100001;

    private textEncoder: TextEncoder;
    private textDecoder: TextDecoder;

    private hashMasterPassword: Promise<ArrayBuffer>;

    constructor(masterPassword: string, salt: string) {
        this.textEncoder = new TextEncoder();
        this.textDecoder = new TextDecoder();

        this.hashMasterPassword = this.hash(masterPassword, salt);
    }

    private async hash(password: string, salt: string): Promise<ArrayBuffer> {
        return crypto.subtle.digest(CryptoService.HASH, this.textEncoder.encode(password.concat(salt)))
            .catch(error => {throw new Error(error)});
    }

    private async generateKeyMaterial(): Promise<CryptoKey> {
        return crypto.subtle.importKey(
            "raw",
            await this.hashMasterPassword,
            { name: "PBKDF2" },
            false,
            ["deriveBits", "deriveKey"]
        );
    }

    private async generateKey(keyMaterial: CryptoKey, salt: Uint8Array): Promise<CryptoKey> {
        const algorithm: string = CryptoService.ALGORITHM;
        const hash: string = CryptoService.HASH;
        const iterationCount: number = CryptoService.ITERATION_COUNT;
        const keySize: number = CryptoService.KEY_SIZE;

        return crypto.subtle.deriveKey(
            {
                name: "PBKDF2",
                salt,
                iterations: iterationCount,
                hash: hash
            },
            keyMaterial,
            {
                name: algorithm,
                length: keySize
            },
            true,
            ["encrypt", "decrypt"]
        );
    }

    private async encrypt(plaintext: string): Promise<string> {
        try {

            const data: Uint8Array = this.textEncoder.encode(plaintext);
            const salt: Uint8Array = crypto.getRandomValues(new Uint8Array(16));
            const keyMaterial: CryptoKey = await this.generateKeyMaterial();
            const key: CryptoKey = await this.generateKey(keyMaterial, salt)
            const algorithm: string = CryptoService.ALGORITHM;
            const iv: Uint8Array = crypto.getRandomValues(new Uint8Array(12));
            const encryptedData: ArrayBuffer = await crypto.subtle.encrypt(
                {
                    name: algorithm,
                    iv
                },
                key,
                data
            );

            const encryptedDataArray: Uint8Array = new Uint8Array(encryptedData)
            const ciphertextBase64: string = btoa(String.fromCharCode.apply(null, Array.from(encryptedDataArray)));
            const ivBase64: string = btoa(String.fromCharCode.apply(null, Array.from(iv)));
            const saltBase64: string = btoa(String.fromCharCode.apply(null, Array.from(salt)));

            return ciphertextBase64 + ":" + ivBase64 + ":" + saltBase64;
        } catch (error) {
            throw new Error("Error encrypting data: " + error);
        }
    }

    private async decrypt(encryptedData: string): Promise<string> {
        try {
            const [ciphertextStr, ivStr, saltStr]: string[] = encryptedData.split(":");
            const ciphertext: Uint8Array = Uint8Array.from(atob(ciphertextStr), c => c.charCodeAt(0));
            const iv: Uint8Array = Uint8Array.from(atob(ivStr), c => c.charCodeAt(0));
            const salt: Uint8Array = Uint8Array.from(atob(saltStr), c => c.charCodeAt(0));
            const algorithm: string = CryptoService.ALGORITHM;
            const keyMaterial: CryptoKey = await this.generateKeyMaterial();
            const key: CryptoKey = await this.generateKey(keyMaterial, salt)

            const decryptedData: ArrayBuffer = await crypto.subtle.decrypt(
                {
                    name: algorithm,
                    iv
                },
                key,
                ciphertext
            );

            return this.textDecoder.decode(decryptedData);
        } catch {
            return encryptedData;
        }
    }

    async decryptCred(cred: Cred): Promise<Cred> {
        const decryptedPassword: string = await this.decrypt(cred.password);
        const decryptedUsername: string = await this.decrypt(cred.username);
        return new Cred(cred.id, cred.siteName, decryptedUsername, decryptedPassword);
    }

    async encryptCred(cred: Cred): Promise<Cred> {
        const encryptedPassword: string = await this.encrypt(cred.password);
        const encryptedUsername: string = await this.encrypt(cred.username);
        return new Cred(cred.id, cred.siteName, encryptedUsername, encryptedPassword);
    }


}
