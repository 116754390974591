export const validate = (email: string, password: string) => {
    const errors: { email: string; password: string; } = {
        email: '',
        password: ''
    };

    if (email.trim() === '') {
        errors.email = 'Почта обязательна для заполнения';
    }
    else if (email.length < 6 || email.length >= 254) {
        errors.email = 'Почта должна быть длиной от 6 до 254 символов';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/.test(email)) {
        errors.email = 'Введенный email некорректен';
    }

    if (password.trim() === '') {
        errors.password = 'Пароль обязателен для заполнения';
    } else if (password.length < 8 || password.length >= 64) {
        errors.password = 'Пароль должен быть от 8 до 30 символов';
    }

    return errors;
};

export const validateSiteName = (siteName: string) => {
    const errors: { siteName: string } = {
        siteName: '',
    };
    if(siteName.length > 256) {
        errors.siteName = 'Название не может быть длинее 256 символов';
    }

    return errors;
};

export const validateMasterPassword = (masterPassword: string) => {
    const errors: { masterPassword: string } = {
        masterPassword: '',
    };

    if (masterPassword.trim() === '') {
        errors.masterPassword = 'Это поле не может быть пустым';
    }

    return errors;
};

export const validateCred = (siteName: string, username: string, password: string) => {
    const errors: { siteName: string; username: string; password: string } = {
        siteName: '',
        username: '',
        password: '',
    };

    if (siteName.trim() === '') {
        errors.siteName = 'Название сайта пустое';
    }
    if (siteName.trim().length > 256) {
        errors.siteName = 'Название сайта должно быть менее 256 символов';
    }

    if (username.trim() === '') {
        errors.username = 'Поле логин пустое';
    }
    if (username.trim().length > 256) {
        errors.username = 'Логин должен быть менее 256 символов';
    }

    if (password.trim() === '') {
        errors.password = 'Поле пароль пустое';
    }
    if (password.trim().length > 256) {
        errors.password = 'Пароль должен быть менее 256 символов';
    }

    return errors;
};