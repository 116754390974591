import React, {useState} from 'react';
import {Cred} from "../Cred.ts";
import {validateCred} from "../../validation/Validator.ts";

interface ActionCredProps {
    cred: Cred;
    onActionCred: (newCred: Cred) => void;
    buttonClassName: string;
    className?: string
}

const ActionCredComponent: React.FC<ActionCredProps> = ({ cred, onActionCred, buttonClassName, className }) => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [siteName, setSiteName] = useState(cred.siteName);
    const [username, setUsername] = useState(cred.username);
    const [password, setPassword] = useState(cred.password);
    const [errors, setErrors] = useState({
        siteName: '',
        username: '',
        password: '',
    });

    const handleOpenModal = () => {
        setIsLoginModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsLoginModalOpen(false);
        setErrors({ siteName: '', username: '', password: '' });
    };


    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newErrors = validateCred(siteName, username, password);
        if (Object.values(newErrors).some((error) => error !== '')) {
            setErrors(newErrors);
        } else {
            const editedCred = new Cred(cred.id, siteName, username, password);
            onActionCred(editedCred);
            setSiteName('');
            setUsername('');
            setPassword('');
            handleCloseModal();
        }
    };

    return (
        <div className={className === undefined ? 'actionCredComponent' : className}>
            <button onClick={handleOpenModal}><i className={buttonClassName}></i></button>
            { isLoginModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <form onSubmit={handleFormSubmit}>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Название сайта"
                                    value={siteName}
                                    onChange={(event) => setSiteName(event.target.value)}
                                />
                                {errors.siteName !== '' && <p style={{color: 'red'}}>{errors.siteName}</p>}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Логин"
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                                {errors.username !== '' && <p style={{color: 'red'}}>{errors.username}</p>}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Пароль"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                {errors.password !== '' && <p style={{color: 'red'}}>{errors.password}</p>}
                            </div>
                            <button type="submit">Сохранить</button>
                            <button onClick={handleCloseModal}>Отмена</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActionCredComponent;