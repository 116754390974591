import React, {createContext, useContext} from "react";
import {CryptoService} from "./CryptoService.ts";
import {Cred} from "../cred/Cred.ts";

const CryptoContext = createContext({
    encryptCred: (cred: Cred): Promise<Cred>  => {},
    decryptCred: (cred: Cred): Promise<Cred> => {},
    setMasterKey: (masterPassword: string, salt: string) => {}
});

export const CryptoProvider = ({ children }: { children: React.ReactNode}) => {

    let cryptoService: CryptoService;

    const setMasterKey = (masterPassword: string, salt: string): void => {
        cryptoService = new CryptoService(masterPassword, salt);
    }

    const encryptCred = async (cred: Cred): Promise<Cred> => {
        if (!cryptoService) throw new Error('CryptoService is not set');
        return cryptoService.encryptCred(cred);
    }

    const decryptCred = (cred: Cred): Promise<Cred> => {
        if (!cryptoService) return Promise.resolve(cred);
        return cryptoService.decryptCred(cred);
    }

    return <CryptoContext.Provider value={{encryptCred, decryptCred, setMasterKey}}>{children}</CryptoContext.Provider>;
}

export const useCrypto = () => useContext(CryptoContext);