import React, {useState} from "react";
import {sendRegisterRequest} from "../request/RequestSender.ts";
import './RegisterComponent.css';
import {LoginButtonComponent} from "../auth/LoginButtonComponent.tsx";
import useErrorParser from "../../error/useErrorParser.ts";
import {validate} from "../validation/Validator.ts";

export default function RegisterComponentDiv({setRegisterSent, setEmail, email, password, setPassword}: {
    setRegisterSent: (value: boolean) => void,
    setEmail: (value: (((prevState: string) => string) | string)) => void,
    email: string,
    password: string,
    setPassword: (value: (((prevState: string) => string) | string)) => void
}) {

    const [errors, setErrors] = useState({email: '', password: ''});
    const [serverError, setServerError] = useState('');
    const errorParser = useErrorParser();
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = validate(email, password);
        if (errors.email !== '' || errors.password !== '') {
            setErrors(errors);
            setServerError('')
            return;
        }
        setErrors({email: '', password: ''});
        sendRegisterRequest(email, password, errorParser)
            .then(() => {
                setRegisterSent(true);

            })
            .catch((error) => {
                setServerError(error.message)
            });
    }

    return (
        <>
            <LoginButtonComponent/>
            <form onSubmit={handleSubmit} className='register-form'>
                <div className='register-form-wrapper'>
                    <div className='email-input'>
                        <input
                            type="text"
                            placeholder="Почта"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        {errors.email !== '' && <p style={{color: 'red'}}>{errors.email}</p>}
                    </div>
                    <div className='password-input'>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Пароль"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        <i className="fa-solid fa-eye"
                           onClick={(event) => {
                               event.preventDefault();
                               setShowPassword(!showPassword)
                           }}/>
                        {errors.password !== '' && <p style={{color: 'red'}}>{errors.password}</p>}
                    </div>
                    <button type="submit"><label>Продолжить</label></button>
                    {serverError !== '' && <p style={{color: 'red'}}>{serverError}</p>}
                </div>
            </form>
        </>
    )
}