import {useState} from "react";
import './RegisterComponent.css';
import RegisterVerifierDiv from "./RegisterVerifierDiv.tsx";
import RegisterComponentDiv from "./RegisterComponentDiv.tsx";

export default function RegisterComponent() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [registerSent, setRegisterSent] = useState(false);

    if (registerSent) {
        return (
            <RegisterVerifierDiv email={email}  setEmail={setEmail}
                                 password={password} setPassword={setPassword} />
        )
    }
    return (
        <RegisterComponentDiv password={password} setPassword={setPassword}
                              email={email} setEmail={setEmail}
                              setRegisterSent={setRegisterSent}/>
    )
}