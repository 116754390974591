import React from "react";
import {validateSiteName} from "../validation/Validator.ts";

interface TodoInputProps {
    filterCred: (newTodo: string) => void;
}

export default function CredFilter ({ filterCred }: TodoInputProps) {
    const [siteName, setSiteName] = React.useState('');
    const [errors, setError] = React.useState({siteName: ''})

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = validateSiteName(siteName);
        if (errors.siteName !== '') {
            setError(errors);
            return;
        } else {
            setError({siteName: ''});
        }
        filterCred(siteName);
    };


    return (
        <form onSubmit={handleSubmit} className="credFilter">
            <input
                type="text"
                value={siteName}
                onChange={(event) => setSiteName(event.target.value)}
                placeholder="Поиск по названию сайта"
            />
            <button type="submit">Поиск</button>
            {errors.siteName !== '' && <p style={{color: 'red'}}>{errors.siteName}</p>}
        </form>

    )
}
