import {CopyTextComponent} from "./CopyTextComponent.tsx";

interface LinkTextComponentProps {
    siteName: string
}

export function LinkTextComponent({siteName}: LinkTextComponentProps) {

    const validUrl = isValidUrl(siteName);
    if (validUrl) {
        return (
            <a href={convertIfNeed(siteName)} target="_blank" rel="noopener noreferrer">{siteName}</a>
        )
    }
    return (
        <CopyTextComponent text={siteName}/>
    )
}

function isValidUrl(url: string): boolean {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return regex.test(url);
}

function convertIfNeed(url: string): string {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }
    return 'http://' + url;
}