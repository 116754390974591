import {ErrorsResponse} from "./errors.ts";
import {AxiosError} from "axios";


const useErrorParser = () => {
    return (error: AxiosError<ErrorsResponse>): Error => {
        if (error.response) {
            const data = error.response.data;
            if (data.errors_description) {
                return  new Error(data.errors_description);
            } else {
                return new Error('Something went wrong');
            }
        } else {
            return new Error('Something went wrong');
        }
    };
};

export default useErrorParser;