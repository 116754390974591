import {useState} from "react";
import {toast} from 'react-toastify';
import DOMPurify from 'dompurify';

interface CopyTextComponentProps {
    text: string,
    showText?: boolean
}

export function CopyTextComponent({text, showText = true}: CopyTextComponentProps) {
    const [isCopied, setIsCopied] = useState(false);

    function copyTextToClipboard(textToCopy: string) {
        if (isCopied) {
            return;
        }
        copyToClipboard(textToCopy)
            .then(() => {
                toast('Текст скопирован');
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Error copying text to clipboard:', error);
            });
    }

    if (showText) {
        return (
            <div onClick={() => copyTextToClipboard(text)} className="copyTextComponent">
                <p>{text}</p>
            </div>
        );
    }
    return (
        <div onClick={() => copyTextToClipboard(text)} className="copyTextComponent">
            <p>{"Top Secret"}</p>
        </div>
    )

}

const copyToClipboard = async (text: string) => {
    const sanitizeText = DOMPurify.sanitize(text);
    if (navigator && navigator.clipboard) {
        await navigator.clipboard.writeText(sanitizeText);
    } else {
        const textarea = document.createElement('textarea');
        textarea.value = sanitizeText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
};