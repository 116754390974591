import React, {useState} from "react";
import {useAuth} from "../auth/AuthContext.tsx";
import {LOGIN_URL} from "../request/RequestSender.ts";
import {Navigate} from "react-router-dom";

export function LogoutComponent() {
    const { isAuthenticated, isLoading, logout } = useAuth();
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        logout().catch((error) => setError(error.message));
        return <Navigate to={LOGIN_URL} />
    }
    if (isLoading) {
        return;
    }
    if (!isAuthenticated) {
        return;
    }
    return (
        <form onSubmit={handleSubmit} className="logoutComponent">
            <button type="submit">Выход</button>
            {error && <p style={{color: 'red'}}>{error}</p>}
        </form>
    );
}