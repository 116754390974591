import {Cred, fromCred, mapCred} from "../cred/Cred.ts";
import {Login} from "../login/Login.ts";
import {Register} from "../register/Register.ts";
import {VerifyCode} from "../register/VerifyCode.ts";
import axios, {AxiosError} from 'axios';
import {ErrorsResponse} from "../../error/errors.ts";

const API_URL: string = '/api/cred';
const CRED_ALL_MAP: string = '/all';
const SALT_URL: string = '/api/salt';
const SEND_SIGN_UP_URL: string = '/sign-up/send';
const VERIFY_SIGN_UP_URL: string = '/sign-up/verify';
const LOGOUT_URL: string = '/exit';

export const AUTH_URL: string = '/auth/token';
export const LOGIN_URL: string = '/login';
export const RECOVERY_URL: string = '/recovery';
export const RECOVERY_CRED_URL: string = '/api/recovery/cred';
export const REGISTER_URL: string = '/register';
export const MAIN_PAGE_URL: string = '/';

axios.defaults.headers.common['Content-Type'] = 'application/json';

export const getAllCreds = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error),
    siteName: string = '',
    page: number = 0,
    size: number = 10,
): Promise<{ totalPages: number; totalElements: number; creds: Cred[] }> => {
    try {
        const url = `${API_URL}${CRED_ALL_MAP}?page=${page}&size=${size}${siteName ? `&site_name=${siteName}` : ''}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status == 403) {
            window.location.href = LOGIN_URL;
        }

        const { totalPages, totalElements, result } = await response.data;
        return {
            totalPages,
            totalElements,
            creds: result.map(mapCred),
        };
    } catch (error: AxiosError<ErrorsResponse>) {
        console.error('Error fetching credentials:', error);
        errorParser(error);
        return { totalPages: 0, totalElements: 0, creds: [] };
    }
};

export const removeCred = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error),
    id: string
) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`);
        if (response.status == 403) {
            window.location.href = LOGIN_URL;
        }
    } catch (error: AxiosError<ErrorsResponse>) {
        errorParser(error);
    }
}

export const addCred = async (
    cred: Cred,
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error)
): Promise<Cred> => {
    try {
        const response = await axios.post(API_URL, fromCred(cred),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        if (response.status == 403) {
            window.location.href = LOGIN_URL;
        }
        return mapCred(await response.data);
    } catch (error: AxiosError<ErrorsResponse>) {
        throw errorParser(error);
    }
}

export const editCred = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error),
    cred: Cred
): Promise<Cred> => {
    try {
        const response = await axios.put(`${API_URL}/${cred.id}`, fromCred(cred),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        if (response.status == 403) {
            window.location.href = LOGIN_URL;
        }
        return mapCred(await response.data);
    } catch (error: AxiosError<ErrorsResponse>) {
        throw errorParser(error);
    }
}

export const authRequest = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error),
    email: string,
    password: string
) => {
    const loginRequest: Login = { email, password };
    try {
        const response = await axios.post(AUTH_URL, loginRequest);
        return response.data;
    } catch (error: AxiosError<ErrorsResponse>) {
        throw errorParser(error);
    }
}

export const checkAuth = async (): Promise<boolean> => {
    return axios.get(AUTH_URL)
        .then(response => {
            return response.status === 200;
        })
        .catch(() => false);
}

export const verifyRegisterRequest = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error),
    code: string,
    email: string
) => {
    const verifyRegisterRequest: VerifyCode = { code, email };
    try {
        const response = await axios.post(VERIFY_SIGN_UP_URL, verifyRegisterRequest);
        return response.data;
    } catch (error: AxiosError<ErrorsResponse>) {
        throw errorParser(error);
    }
}

export const sendRegisterRequest = async (
    email: string,
    password: string,
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error)
) => {
    const registerRequest: Register = { email, password };
    try {
        const response = await axios.post(SEND_SIGN_UP_URL, registerRequest);
        return response.data;
    } catch (error: AxiosError<ErrorsResponse>) {
        throw errorParser(error);
    }
}

export const logoutRequest = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error)
) => {
    await axios.delete(LOGOUT_URL).catch(e => errorParser(e));
}

export const getSalt = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error)
) => {
    try {
        const response = await axios.get(SALT_URL);
        return response.data;
    } catch (error: AxiosError<ErrorsResponse>) {
        throw errorParser(error);
    }
};

export const downloadRecoveryCred = async (
    errorParser: (error: AxiosError<ErrorsResponse>) => (Error)
)  => {
    try {
        const response = await axios.get(RECOVERY_CRED_URL, { responseType: 'blob' });
        return window.URL.createObjectURL(new Blob([response.data]));
    } catch (error: AxiosError<ErrorsResponse>) {
        throw errorParser(error);
    }
}